import { css } from '@emotion/css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { colors } from '../../constants';

type Props = {
  bearing: number;
  onChange: (z: number) => void;
};

export default function BearingSlider({ bearing, onChange }: Props) {
  console.log(bearing);
  const step = 5;
  const roundedBearing = Math.round(bearing / step) * step;
  return (
    <div className={style}>
      <Slider
        defaultValue={bearing}
        value={roundedBearing}
        min={0}
        max={355}
        step={5}
        styles={{
          rail: { height: 7, width: '100%' },
          track: {
            height: 7,
            backgroundColor: colors.primaryLight,
          },
          handle: {
            width: 25,
            height: 25,
            backgroundColor: 'white',
            opacity: 100,
            top: 0,
            border: 'none',
          },
        }}
        onChange={(value) => onChange(value as number)}
      />
    </div>
  );
}

const style = css({
  position: 'absolute',
  height: 20,
  width: '85%',
  bottom: 10,
  left: '7.5%',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
});
