import { css } from '@emotion/css';
import montisLogo from '../../images/montis-corp-full-logo.png';

export default function Attribution() {
  return (
    <div className={style}>
      <a href="https://montiscorp.com" target="_blank" rel="noreferrer">
        <img src={montisLogo} />
      </a>
    </div>
  );
}

const style = css({
  left: 0,
  height: 38,
  padding: '0px 0px',
  borderTopRightRadius: 5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: 5,
  a: {
    textDecoration: 'none',
    color: 'rgb(27, 69, 109)',
    fontWeight: 500,
    fontSize: '.45em',
  },
  img: {
    aspectRatio: 2.5,
    height: 32,
  },
});
