import { css } from '@emotion/css';
import { FetchMode } from '../../state';

type Props = {
  mode: FetchMode;
  onChange: (m: string) => void;
};

export default function ImageFetchMode({ mode, onChange }: Props) {
  return (
    <div className={imageFetchModeStyle}>
      <span>Image Fetch Mode</span>
      <select onChange={onChange} defaultValue={mode}>
        <option value="single" disabled>
          Single
        </option>
        <option value="foveated">Foveated</option>
        <option value="all" disabled>
          All
        </option>
      </select>
    </div>
  );
}

const imageFetchModeStyle = css({
  display: 'none',
  position: 'absolute',
  top: 125,
  left: 0,
  backgroundColor: '#ffffffcc',
  padding: '5px 15px',
  borderBottomRightRadius: '5px',
  flexDirection: 'column',
  width: 200,
  select: {
    margin: '5px 0',
  },
});
