import { CameraImages, MwosDetails } from './_types';

const PROD_API = 'https://api.montiscorp.com';
const LOCAL_API = 'http://localhost:12345';

const baseURL = PROD_API;
const authorization = 'VESTUG2IIGDKKCDJFDQC6ZZAODETADWB';

export const getStationInfo = async (stationId): Promise<MwosDetails> => {
  const req = await fetch(`${baseURL}/mwos/${stationId}`, {
    headers: {
      authorization,
    },
  });
  const json = await req.json();
  console.log(json);
  return json;
};

export const getCameraImages = async (
  cameraId: number
): Promise<CameraImages> => {
  const req = await fetch(
    `${baseURL}/cameras/${cameraId}/images?stationType=mwos&limit=36`,
    {
      headers: {
        authorization,
      },
    }
  );
  const json = await req.json();
  console.log(json);
  return json;
};
