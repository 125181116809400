export default function DemoWarning() {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 50,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <span
        style={{
          color: 'white',
          fontWeight: 600,
          fontSize: '1.4em',
          backgroundColor: '#00000088',
          padding: '10px 20px',
          borderRadius: 3,
          textAlign: 'center',
        }}
      >
        This page is for demonstration purposes only. Images and weather data
        are not current.
      </span>
    </div>
  );
}
