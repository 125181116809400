import { css } from '@emotion/css';
import { useEffect, useRef } from 'react';
import { FetchMode, useStore } from '../../state';
import { Config } from '../../types';
import init from './init';

type Props = {
  fps: number;
  setBearing: (b: number) => void;
  setElevation: (e: number) => void;
  showStats: boolean;
  fetchMode: FetchMode;
  config: Config;
  isDemo?: boolean;
};

type Unsub = () => void;

export default function Viewer({
  fps,
  setBearing,
  setElevation,
  showStats,
  fetchMode,
  config,
  isDemo,
}: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const globalImages = useStore((s) => s.images);
  if (globalImages) {
    config.cameras.camera1.defaultImage = globalImages[0];
    config.cameras.camera2.defaultImage = globalImages[1];
    config.cameras.camera3.defaultImage = globalImages[2];
    config.cameras.camera4.defaultImage = globalImages[3];
    config.refresh.mode = 'static';
  } else {
    config.refresh.mode = 'foveated';
  }

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const viewer = init({
      canvas: canvasRef.current,
      config,
      setBearing,
      setElevation,
      showStats,
      fetchMode,
      isDemo,
    });

    const unsubs: Unsub[] = [];
    if (config.controls.showZoomSlider) {
      unsubs.push(
        useStore.subscribe(
          (state) => state.zoom,
          (zoom) => viewer.setZoom(zoom)
        )
      );
    }
    if (config.controls.showPanSlider) {
      unsubs.push(
        useStore.subscribe(
          (state) => state.bearing,
          (bearing) => {
            viewer.setBearing(bearing);
          }
        )
      );
    }

    // unsubs.push(
    //   useStore.subscribe(
    //     (s) => s.images,
    //     (images) => viewer.setImages(images)
    //   )
    // );

    return () => {
      unsubs.map((u) => u());
      viewer.dispose();
    };
  }, [globalImages]);

  return <canvas className={style} ref={canvasRef} />;
}

const style = css({
  position: 'absolute',
  width: '100%!important',
  height: '100%!important',
});
