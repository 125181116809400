import { Config } from '../types';

const config: Config = {
  baseUrl: 'https://pano.montiscorp.com/rampart/img',
  // baseUrl: 'http://192.168.1.157:8083',
  title: 'Rampart MWOS Panorama - Montis Corp',
  defaultLookAngle: 0,
  defaultFov: 60,
  azimuthOffset: -93,
  latitude: 65.51125,
  longitude: -150.15225,
  stationId: 2,
  refresh: {
    mode: 'foveated',
    intervalMs: 2000,
  },
  controls: {
    showStationInfo: true,
    showAzimuthIndicator: true,
    showElevationIndicator: true,
    showWarning: false,
    showPanSlider: false,
    showZoomSlider: false,
    allowTouch: true,
    allowScroll: true,
    showPanoPicker: true,
  },
  cameras: {
    camera1: {
      defaultImage:
        'https://d27xevwqdit1m8.cloudfront.net/current/mwos/rampart/0-full.jpg',
      azimuthAngle: 0,
      uniforms: {
        rotation: { value: 0.09 },
        vshift: { value: 0.062 },
        hshift: { value: -0.04 },
        brighten: { value: 1.15 },
        blueAdjust: { value: 1.07 },
        redAdjust: { value: 0.97 },
      },
    },
    camera2: {
      defaultImage:
        'https://d27xevwqdit1m8.cloudfront.net/current/mwos/rampart/90-full.jpg',
      azimuthAngle: 90,
      uniforms: {
        rotation: { value: 0.03 },
        vshift: { value: 0.025 },
        hshift: { value: 0.022 },
        brighten: { value: 1.15 },
        blueAdjust: { value: 1 },
        redAdjust: { value: 1 },
      },
    },
    camera3: {
      defaultImage:
        'https://d27xevwqdit1m8.cloudfront.net/current/mwos/rampart/180-full.jpg',
      azimuthAngle: 180,
      uniforms: {
        rotation: { value: 0.02 },
        vshift: { value: 0.013 },
        hshift: { value: 0.02 },
        brighten: { value: 1.1 },
        blueAdjust: { value: 1 },
        redAdjust: { value: 0.92 },
      },
    },
    camera4: {
      defaultImage:
        'https://d27xevwqdit1m8.cloudfront.net/current/mwos/rampart/270-full.jpg',
      azimuthAngle: 270,
      uniforms: {
        rotation: { value: 0 },
        vshift: { value: 0.0 },
        hshift: { value: 0.0 },
        brighten: { value: 1.12 },
        blueAdjust: { value: 0.96 },
        redAdjust: { value: 1 },
      },
    },
  },
  labels: [],
};

export default config;
