import { css } from '@emotion/css';
import { DateTime } from 'luxon';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useStore } from '../../state';
import { getCameraImages, getStationInfo } from './_data-fetching';
import { closestTime, displayDatetime } from './_time-functions';
import { CameraImage, MwosDetails } from './_types';

type Props = {
  stationId: number;
};

export default function PanoPicker({ stationId }: Props) {
  const [station, setStation] = useState<MwosDetails>();
  const [images, setImages] = useState<CameraImage[]>();
  const setGlobalImages = useStore((s) => s.setImages);

  useEffect(() => {
    const getImages = async () => {
      const stationInfo = await getStationInfo(stationId);
      const cameraId = stationInfo.cameras[0].id;
      const images = await getCameraImages(cameraId);
      setStation(stationInfo);
      setImages(images);
    };

    getImages();

    const refreshInterval = setInterval(getImages, 60e3);

    return () => clearInterval(refreshInterval);
  }, [stationId]);

  const selectImages = useCallback(
    async (event: ChangeEvent<HTMLSelectElement>) => {
      if (!station) {
        return;
      }

      const selectedTime = event.target.value;

      if (selectedTime === 'live') {
        setGlobalImages();
        return;
      }

      const cameras = await Promise.all([
        getCameraImages(station.cameras.find((c) => c.bearingDegrees === 0).id),
        getCameraImages(
          station.cameras.find((c) => c.bearingDegrees === 90).id
        ),
        getCameraImages(
          station.cameras.find((c) => c.bearingDegrees === 180).id
        ),
        getCameraImages(
          station.cameras.find((c) => c.bearingDegrees === 270).id
        ),
      ]);

      const images = cameras.map((camImages: CameraImage[]) => {
        const closest = closestTime(
          selectedTime,
          camImages.map((i) => i.observationTime)
        );
        return camImages.find((img) =>
          DateTime.fromISO(img.observationTime).equals(closest)
        )!.imageUrl;
      });

      setGlobalImages(images);
    },
    [station]
  );

  return (
    <div className={style}>
      <select onChange={selectImages}>
        <option value="live">Live Stream</option>
        {images &&
          images.map((img) => (
            <option
              key={img.id}
              value={img.observationTime}
            >{`${displayDatetime(img.observationTime)}`}</option>
          ))}
      </select>
    </div>
  );
}

const style = css({
  position: 'absolute',
  bottom: 0,
  left: 0,

  select: {
    width: 250,
    backgroundColor: '#ffffffdd',
    outline: 'none',
    borderWidth: 0,
  },
});
