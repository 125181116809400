import React, { useCallback } from 'react';
import Controls from './components/Controls/Controls';
import Viewer from './components/Viewer/Viewer';
import { useStore } from './state';
import { Config } from './types';

import merrill from './config/merrill';
import rampart from './config/rampart';

const MemoViewer = React.memo(Viewer, () => true);

type Props = {
  config?: Config;
  stationId?: number;
};

export default function Pano({ config, stationId }: Props) {
  const bearing = useStore((store) => store.bearing);
  const setBearing = useStore((store) => store.setBearing);
  const altitude = useStore((store) => store.altitude);
  const setAltitude = useStore((store) => store.setAltitude);
  const fetchMode = useStore((state) => state.fetchMode);

  const setBearingCb = useCallback(
    (bearing: number) => setBearing(bearing),
    []
  );
  const setAltitudeCb = useCallback(
    (altitude: number) => setAltitude(altitude),
    []
  );

  if (!config?.stationId) {
    let cfg;
    if (stationId === 1) {
      cfg = merrill;
    }
    if (stationId === 2) {
      cfg = rampart;
    }
    if (config) {
      Object.assign(cfg.controls, config.controls);
      Object.assign(cfg.labels, config.labels);
    }
    config = cfg;
  }

  const isDemo = window.location.href.match(/demo/g);

  return (
    <>
      <MemoViewer
        config={config}
        fps={0.5}
        setBearing={setBearingCb}
        setElevation={setAltitudeCb}
        showStats={false}
        fetchMode={fetchMode}
        isDemo={isDemo}
      />
      <Controls
        stationId={config?.stationId}
        showStationInfo={config?.controls.showStationInfo}
        showAzimuthIndicator={config?.controls.showAzimuthIndicator}
        showElevationIndicator={config?.controls.showElevationIndicator}
        bearing={bearing}
        elevation={altitude}
        isDemo={isDemo}
        showWarning={config?.controls.showWarning}
        showZoomSlider={config?.controls.showZoomSlider}
        showPanSlider={config?.controls.showPanSlider}
        showPanoPicker={config?.controls.showPanoPicker}
      />
    </>
  );
}
