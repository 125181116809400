import Stats from 'stats.js';
import {
  BackSide,
  BoxGeometry,
  Clock,
  Color,
  LinearFilter,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  ShaderMaterial,
  SphereGeometry,
  TextureLoader,
  Vector3,
  WebGLRenderer,
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

import { bearing as calcBearing } from '@turf/bearing';
import { distance as calcDistance } from '@turf/distance';
import { point } from '@turf/helpers';

import { d2r, r2d } from '../../utils';

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { maxZoom } from '../../constants';
import { FetchMode } from '../../state';
import { Config } from '../../types';
import fs from './fs.glsl';
import vs from './vs.glsl';

type Props = {
  canvas: HTMLCanvasElement;
  config: Config;
  setBearing: (b: number) => void;
  setElevation: (e: number) => void;
  showStats: boolean;
  fetchMode: FetchMode;
  isDemo: boolean;
};

type Viewer = {
  dispose: () => void;
  setZoom: (z: number) => void;
  setBearing: (b: number) => void;
};

export default function init({
  canvas,
  config,
  setBearing,
  setElevation,
  showStats,
  fetchMode,
  isDemo,
}: Props): Viewer {
  const scene = new Scene();
  scene.background = new Color(0x000000);

  const camera = new PerspectiveCamera(
    config.defaultFov,
    canvas.clientWidth / canvas.clientHeight,
    0.1,
    2000
  );
  camera.position.set(
    ...lookAtAngle(config.defaultLookAngle + config.azimuthOffset)
    // ...lookAtAngle(-35)
  );
  camera.lookAt(0, 0, 0);
  camera.updateProjectionMatrix();

  const renderer = new WebGLRenderer({
    canvas,
    antialias: true,
    powerPreference: 'high-performance',
    stencil: false,
  });
  renderer.setSize(canvas.clientWidth, canvas.clientHeight);

  const texLoader = new TextureLoader();

  const image1 = texLoader.load(config.cameras.camera1.defaultImage);
  image1.minFilter = LinearFilter;
  const image2 = texLoader.load(config.cameras.camera2.defaultImage);
  image2.minFilter = LinearFilter;
  const image3 = texLoader.load(config.cameras.camera3.defaultImage);
  image3.minFilter = LinearFilter;
  const image4 = texLoader.load(config.cameras.camera4.defaultImage);
  image4.minFilter = LinearFilter;

  const proj1Uniforms = {
    image: { value: image1 },
    ...config.cameras.camera1.uniforms,
  };
  const projMesh1 = createImageMesh({ uniforms: proj1Uniforms });

  const proj2Uniforms = {
    image: { value: image2 },
    ...config.cameras.camera2.uniforms,
  };
  const projMesh2 = createImageMesh({ uniforms: proj2Uniforms });
  projMesh2.rotateY(d2r(-90));

  const proj3Uniforms = {
    image: { value: image3 },
    ...config.cameras.camera3.uniforms,
  };
  const projMesh3 = createImageMesh({ uniforms: proj3Uniforms });
  projMesh3.rotateY(d2r(-180));

  const proj4Uniforms = {
    image: { value: image4 },
    ...config.cameras.camera4.uniforms,
  };
  const projMesh4 = createImageMesh({ uniforms: proj4Uniforms });
  projMesh4.rotateY(d2r(-270));

  scene.add(projMesh1);
  scene.add(projMesh2);
  scene.add(projMesh3);
  scene.add(projMesh4);

  if (config.title.match(/Merrill/g)) {
    const blurMesh = new Mesh(
      new BoxGeometry(120, 8, 0.1),
      new MeshBasicMaterial({
        color: 0x0c2730,
      })
    );
    blurMesh.position.set(61, -2.75, -29);
    blurMesh.lookAt(camera.position);
    blurMesh.rotateZ(0.115);
    blurMesh.rotateY(1.1);
    scene.add(blurMesh);
  }

  const controls = new OrbitControls(camera, canvas);
  controls.enablePan = false;

  const onWindowResize = () => {
    // const canvas = canvasRef.current as HTMLCanvasElement;
    camera.aspect = canvas.clientWidth / canvas.clientHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(canvas.clientWidth, canvas.clientHeight);
  };
  window.addEventListener('resize', onWindowResize);
  onWindowResize();

  const sleep = async () => new Promise((resolve) => setTimeout(resolve, 1000));

  texLoader.crossOrigin = 'anonymous';

  // const cameraDirection = new Vector3();
  // const bearingOffset = 0;

  let disposed = false;

  const getTargetImage = () => {
    const cameraDirection = new Vector3();
    camera.getWorldDirection(cameraDirection);
    let cameraDir = r2d(
      new Vector3(cameraDirection.x, 0, cameraDirection.z).angleTo(
        new Vector3(1, 0, 0)
      )
    );
    if (cameraDirection.z < 0) {
      cameraDir = 360 - cameraDir;
    }
    // console.log(cameraDir, cameraDirection);
    let imageNum = 1;
    let texUniform;
    if (cameraDir >= -45 && cameraDir < 45) {
      imageNum = 1;
      texUniform = proj1Uniforms.image;
    } else if (cameraDir >= 45 && cameraDir < 135) {
      imageNum = 2;
      texUniform = proj2Uniforms.image;
    } else if (cameraDir >= 135 && cameraDir < 225) {
      imageNum = 3;
      texUniform = proj3Uniforms.image;
    } else {
      imageNum = 4;
      texUniform = proj4Uniforms.image;
    }
    return [imageNum, texUniform];
  };

  const refreshOne = async (imageNum, textureUniform, quality = 'low') => {
    const d = new Date().valueOf();
    const compression = quality === 'high' ? 5 : 5;
    // const compression = 75;
    const avifenc_min = quality === 'high' ? 6 : 24;
    const avifenc_max = quality === 'high' ? 56 : 64;
    const avifenc_speed = quality === 'high' ? 8 : 9;
    const url = `${config.baseUrl}/${imageNum}?compression=${compression}&avifenc_min=${avifenc_min}&avifenc_max=${avifenc_max}&avifenc_speed=${avifenc_speed}&d=${d}`;
    const tex = await texLoader.loadAsync(url);
    const oldTex = textureUniform.value;
    textureUniform.value = tex;
    oldTex.dispose();
  };

  let refreshInterval: number;

  // Foveated refresh
  const foveatedRefresh = async () => {
    const [imageNum, textureUniform] = getTargetImage();
    refreshOne(1, proj1Uniforms.image, imageNum === 1 ? 'high' : 'low');
    refreshOne(2, proj2Uniforms.image, imageNum === 2 ? 'high' : 'low');
    refreshOne(3, proj3Uniforms.image, imageNum === 3 ? 'high' : 'low');
    refreshOne(4, proj4Uniforms.image, imageNum === 4 ? 'high' : 'low');
  };
  if (config.refresh.mode === 'foveated' && !isDemo) {
    refreshInterval = setInterval(foveatedRefresh, config.refresh.intervalMs);
  }

  const demoImagesBaseUrl =
    'https://montis-panorama-demo-images.s3.amazonaws.com/';
  const demoRefresh = async (index: number) => {
    {
      const tex = await texLoader.loadAsync(
        `${demoImagesBaseUrl}1/${index}.avif`
      );
      const old = proj1Uniforms.image.value;
      proj1Uniforms.image.value = tex;
      old.dispose();
    }
    {
      const tex = await texLoader.loadAsync(
        `${demoImagesBaseUrl}2/${index}.avif`
      );
      const old = proj2Uniforms.image.value;
      proj2Uniforms.image.value = tex;
      old.dispose();
    }
    {
      const tex = await texLoader.loadAsync(
        `${demoImagesBaseUrl}3/${index}.avif`
      );
      const old = proj3Uniforms.image.value;
      proj3Uniforms.image.value = tex;
      old.dispose();
    }
    {
      const tex = await texLoader.loadAsync(
        `${demoImagesBaseUrl}4/${index}.avif`
      );
      const old = proj4Uniforms.image.value;
      proj4Uniforms.image.value = tex;
      old.dispose();
    }
  };
  if (isDemo) {
    let index = 0;
    refreshInterval = setInterval(() => {
      demoRefresh(index);
      index += 1;
      if (index >= 60) {
        index = 0;
      }
    }, 2e3);
  }

  // Bearing ring
  // const makeBearingRing = async () => {
  //   const ringTex = await texLoader.loadAsync(bearingRingImage);
  //   const mesh = new Mesh(
  //     new CylinderGeometry(sphereRadius / 3, sphereRadius / 3, 7, 32, 10, true),
  //     new MeshBasicMaterial({
  //       map: ringTex,
  //       side: DoubleSide,
  //       transparent: true,
  //       depthWrite: false,
  //       depthTest: false,
  //     })
  //   );
  //   mesh.position.setY(5);
  //   scene.add(mesh);
  // };
  // makeBearingRing();

  // Single image refresh
  const refreshImg = async () => {
    if (disposed) {
      return;
    }
    await new Promise((resolve) => {
      const d = new Date().valueOf();
      const [imageNum, textureUniform] = getTargetImage();
      texLoader.load(
        `${config.baseUrl}/${imageNum}?compression=5&avifenc_min=8&avifenc_max=56&avifenc_speed=10&d=${d}`,
        (tex) => {
          textureUniform.value = tex;
          resolve(1);
        },
        undefined,
        (err) => {
          resolve(0);
        }
      );
    });
    await sleep();
    refreshImg();
  };
  // refreshImg();

  let stats: Stats;
  let statsBegin = () => {};
  let statsEnd = () => {};
  if (showStats) {
    stats = new Stats();
    stats.showPanel(0);
    document.body.appendChild(stats.dom);
    stats.dom.className = 'stats';
    statsBegin = stats.begin;
    statsEnd = stats.end;
  }

  const maxFov = 100;
  const minFov = 5;
  // let cameraAzimuthalAngle = config.defaultLookAngle;

  const getCameraAzimuthalAngle = () => {
    const cameraDirection2 = new Vector3();
    camera.getWorldDirection(cameraDirection2);

    let cameraDir = r2d(
      new Vector3(cameraDirection2.x, 0, cameraDirection2.z).angleTo(
        new Vector3(1, 0, 0)
      )
    );
    if (cameraDirection2.z < 0) {
      cameraDir = 360 - cameraDir;
    }
    // NOTE: Only works for Rampart
    // Basically, we need a better way to easily go between camera 3d position
    // and azimuthal/bearing angles in a consistent way. We could hardcode a
    // working transform for Merrill, but the real solution needs to be a formula
    // that takes azimuthOffset into account.
    cameraDir = Math.round(cameraDir) - 3;

    // console.log('getAngle', cameraDir);
    return cameraDir;
  };

  const keyboardEventListener = createKeyboardControls(canvas, {
    ArrowUp: () => {
      camera.fov -= 3.0;
      camera.updateProjectionMatrix();
    },
    ArrowDown: () => {
      camera.fov += 3.0;
      camera.updateProjectionMatrix();
    },
    ArrowLeft: () => {
      const angle = getCameraAzimuthalAngle();
      const newPos = lookAtAngle(-angle + config.azimuthOffset + 5);
      camera.position.setX(newPos[0]);
      camera.position.setZ(newPos[2]);
      camera.updateProjectionMatrix();
    },
    ArrowRight: () => {
      const angle = getCameraAzimuthalAngle();
      const newPos = lookAtAngle(-angle + config.azimuthOffset - 5);
      camera.position.setX(newPos[0]);
      camera.position.setZ(newPos[2]);
      camera.updateProjectionMatrix();
    },
  });

  createPinchControls(
    canvas,
    () => {
      camera.fov += 0.5;
      camera.fov = Math.max(minFov, Math.min(camera.fov, maxFov));
      camera.updateProjectionMatrix();
    },
    () => {
      camera.fov -= 0.5;
      camera.fov = Math.max(minFov, Math.min(camera.fov, maxFov));
      camera.updateProjectionMatrix();
    }
  );

  controls.enableZoom = false;
  let onMouseWheel;
  if (config.controls.allowScroll) {
    onMouseWheel = (event: WheelEvent) => {
      camera.fov += event.deltaY / 80.0;
      camera.fov = Math.max(minFov, Math.min(camera.fov, maxFov));
      controls.rotateSpeed = (-1 * camera.fov) / 300;
      camera.updateProjectionMatrix();
    };
    canvas.addEventListener('wheel', onMouseWheel);
  }
  controls.rotateSpeed = (-1 * camera.fov) / 300;

  const controlsUpdatesInterval = setInterval(() => {
    const cameraDirection = new Vector3();
    camera.getWorldDirection(cameraDirection);

    let cameraDir = r2d(
      new Vector3(cameraDirection.x, 0, cameraDirection.z).angleTo(
        new Vector3(1, 0, 0)
      )
    );
    if (cameraDirection.z < 0) {
      cameraDir = 360 - cameraDir;
    }
    // const cameraDir = getCameraAzimuthalAngle();
    const altitude =
      r2d(
        new Vector3(0, cameraDirection.y, -1)
          .normalize()
          .angleTo(new Vector3(0, 0, -1))
      ) *
      2 *
      (cameraDirection.y < 0 ? -1 : 1);

    // NOTE: Only works for Rampart
    // Basically, we need a better way to easily go between camera 3d position
    // and azimuthal/bearing angles in a consistent way. We could hardcode a
    // working transform for Merrill, but the real solution needs to be a formula
    // that takes azimuthOffset into account.
    cameraDir = Math.round(cameraDir) - 3;

    setBearing(cameraDir);
    setElevation(altitude);
  }, 200);

  const annotations: Mesh[] = [];
  const loadText = async () => {
    const stationPoint = point([config.longitude, config.latitude]);
    for (const l of config.labels) {
      const labelPoint = point([l.longitude, l.latitude]);
      const bearing = calcBearing(stationPoint, labelPoint);
      const distance = calcDistance(stationPoint, labelPoint);
      const text = `${l.name}\n${distance.toFixed(0)} km`;
      const annotation = await createAnnotation(
        text,
        bearing,
        l.elevation,
        distance
      );
      annotations.push(annotation);
      scene.add(annotation);
    }
  };
  loadText();

  const updateAnnotations = () => {
    annotations.forEach((a) => a.lookAt(camera.position));
  };

  const clock = new Clock();
  const animate = () => {
    if (disposed) {
      return;
    }
    requestAnimationFrame(animate);
    statsBegin();
    controls.update(clock.getDelta());
    updateAnnotations();
    renderer.render(scene, camera);
    statsEnd();
  };
  animate();

  return {
    dispose: () => {
      disposed = true;
      renderer.dispose();
      controls.dispose();
      window.removeEventListener('resize', onWindowResize);
      window.removeEventListener('wheel', onMouseWheel);
      stats && document.body.removeChild(stats.dom);
      clearInterval(controlsUpdatesInterval);
      clearInterval(refreshInterval);
      window.removeEventListener('keydown', keyboardEventListener);
    },
    setZoom: (z: number) => {
      camera.fov = (1 - z / maxZoom) * maxFov;
      camera.fov = Math.max(minFov, Math.min(camera.fov, maxFov));
      controls.rotateSpeed = (-1 * camera.fov) / 300;
      camera.updateProjectionMatrix();
    },
    setBearing: (b: number) => {
      const pos = lookAtAngle(360 - b + config.azimuthOffset);
      camera.position.setX(pos[0]);
      camera.position.setZ(pos[2]);
      camera.updateProjectionMatrix();
    },
  };
}

const lookAtAngle = (deg: number) => {
  return [Math.sin(d2r(deg)), 0, Math.cos(d2r(deg))];
};

const lookAtImageNum = (n: number) => {
  switch (n) {
    case 1:
      return [-7, 0, 0];
    case 2:
      return [0, 0, -7];
    case 3:
      return [7, 0, 0];
    case 4:
      return [0, 0, 7];
    case 34:
      return [7, 0, 7];
  }
};

const sphereRadius = 60;
const sphereWidthSegments = 60;
const sphereHeightSegments = 60;

const createImageMesh = ({ uniforms }) => {
  const mesh = new Mesh(
    new SphereGeometry(sphereRadius, sphereWidthSegments, sphereHeightSegments),
    new ShaderMaterial({
      fragmentShader: fs,
      vertexShader: vs,
      side: BackSide,
      uniforms,
      opacity: 0.0,
      transparent: true,
      depthWrite: false,
    })
  );
  mesh.position.set(0, 0, 0);
  return mesh;
};

const createAnnotation = async (
  text: string,
  bearing: number,
  altitude: number,
  distance: number
) => {
  const loader = new FontLoader();
  const font = await loader.loadAsync(
    'https://raw.githubusercontent.com/mrdoob/three.js/master/examples/fonts/helvetiker_bold.typeface.json'
  );
  const geom = new TextGeometry(text, {
    font,
    size: 0.25,
    height: 0.01,
    depth: 0.001,
    curveSegments: 15,
    bevelEnabled: false,
    bevelThickness: 0.0001,
    bevelSize: 0.0001,
    bevelOffset: 0,
    bevelSegments: 1,
  });
  const material = new MeshBasicMaterial({ color: 0xaa0000 });
  const mesh = new Mesh(geom, material);

  // console.log({ text, distance, bearing });

  // TODO: Both distance and altitude need to be correctly calculated
  // This is just an a quick-and-dirty estimation
  mesh.position.setFromCylindricalCoords(
    distance * 1.8,
    d2r(-bearing + 90),
    altitude / 450
  );

  return mesh;
};

const createPinchControls = (
  target: HTMLElement,
  onPinchIn: () => void,
  onPinchOut: () => void
) => {
  const downPointers: PointerEvent[] = [];
  let prevDiff = -1;

  const handlePointerDown = (event: PointerEvent) => {
    downPointers.push(event);
  };
  const handlePointerUp = (event: PointerEvent) => {
    const index = downPointers.findIndex(
      (e) => e.pointerId === event.pointerId
    );
    downPointers.splice(index, 1);
  };
  const handlePointerMove = (event: PointerEvent) => {
    const index = downPointers.findIndex(
      (e) => e.pointerId === event.pointerId
    );
    downPointers[index] = event;

    if (downPointers.length !== 2) {
      return;
    }

    const diff = Math.abs(downPointers[0].clientX - downPointers[1].clientX);
    if (prevDiff > 0) {
      if (diff > prevDiff) {
        onPinchOut();
      }
      if (diff < prevDiff) {
        onPinchIn();
      }
    }
    prevDiff = diff;
  };

  target.onpointerdown = handlePointerDown;
  target.onpointerup = handlePointerUp;
  target.onpointermove = handlePointerMove;
};

const createKeyboardControls = (target: HTMLElement, actions) => {
  const keydown = (event: KeyboardEvent) => {
    const action = actions[event.key];
    if (action) {
      action();
    }
  };
  window.addEventListener('keydown', keydown);
  return keydown;
};
