import { css } from '@emotion/css';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { colors, maxZoom, minZoom } from '../../constants';

type Props = {
  zoom: number;
  onChange: (z: number) => void;
};

export default function ZoomSlider({ zoom, onChange }: Props) {
  return (
    <div className={style}>
      <Slider
        defaultValue={zoom}
        min={minZoom}
        max={maxZoom}
        step={5}
        vertical={true}
        styles={{
          rail: { width: 7, height: '100%' },
          track: {
            width: 7,
            backgroundColor: colors.primaryLight,
          },
          handle: {
            width: 25,
            height: 25,
            backgroundColor: 'white',
            opacity: 100,
            left: 1,
            border: 'none',
          },
        }}
        onChange={(value) => onChange(value as number)}
      />
    </div>
  );
}

const style = css({
  position: 'absolute',
  right: 25,
  width: 20,
  top: '7.5%',
  height: '85%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
