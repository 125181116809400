import { css } from '@emotion/css';

type Props = {
  azimuth: number;
};

export default function AzimuthIndicator({ azimuth }: Props) {
  return (
    <div className={style}>
      <span>Azimuth</span>
      <span>{azimuth.toFixed(1)}°</span>
    </div>
  );
}

const style = css({
  position: 'absolute',
  width: '100px',
  height: 75,
  right: 'calc(100%/2 - 50px)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '5px 15px',
  backgroundColor: '#ffffffcc',
  borderRadius: '0 0 5px 5px',
  'span:nth-child(2)': {
    fontSize: '1.75em',
  },
  '@media (max-width: 800px)': {
    top: 75,
    right: 0,
  },
});
